import Mapbox from 'mapbox-gl'
// import { MglMap } from 'vue-mapbox'
import { Loader } from '@googlemaps/js-api-loader'

import { ServiceFactory } from '@/services/ServiceFactory'
const _barsService = ServiceFactory.get('BarsService')

export default {
  name: 'ZbTabCompaniesLocationComponent',
  // components: {
  //   MglMap
  // },
  data () {
    return {
      accessToken: 'pk.eyJ1IjoiYm9saXJleXBhIiwiYSI6ImNrbGs2ZzEyaTBwdTkybm13Yzg0amJhbGsifQ.Ca9VqUAgMdUhmj6Nrnp9Uw', // your access token. Needed if you using Mapbox maps
      mapStyle: 'mapbox://styles/mapbox/streets-v11', // your map style
      location: {
        latitude: null,
        // latitude: -17.1790134366781,
        // longitude: -66.1422156379736
        longitude: null
      },
      zoom: 5,
      errorCoordinates: null,
      barData: [],
      loading: false,
      postData: {
        location: {
          latitude: 0,
          longitude: 0
        }
      },
      loader: new Loader({
        apiKey: 'AIzaSyCGYd-2KbXhFAerU44LNsdR0UB51oUPkO4',
        version: 'weekly',
        libraries: ['places']
      }),
      mapOptions: {
        center: {
          lat: 0,
          lng: 0
        },
        zoom: 4
      },
      newPosition: false
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      if (this.$route.params.id) {
        // console.log(this.$route.params.id)
        try {
          this.barData = await _barsService.show(this.$route.params.id)
          console.log(this.barData)
          const location = this.barData.bar.location
          this.postData.location = location
          console.log(this.location)
          if (location.latitude || location.longitude) {
            this.postData.location = location
          } else {
            this.postData.location = {
              latitude: 0,
              longitude: 0
            }
          }
          if (location.latitude === 0 || location.longitude === 0) {
            this.postData.location.latitude = null
            this.postData.location.longitude = null
          }
          if (this.postData.location.latitude && this.postData.location.longitude) {
            this.location = {
              latitude: this.postData.location.latitude,
              longitude: this.postData.location.longitude
            }
            this.zoom = 15
          } else {
            // default Bolivia
            this.location = {
              latitude: -17.1790134366781,
              longitude: -66.1422156379736
            }
            this.zoom = 4
            this.newPosition = true
          }
          this.mapOptions.center.lat = this.location.latitude
          this.mapOptions.center.lng = this.location.longitude
          this.mapOptions.zoom = this.zoom
          this.loader
            .load()
            .then((google) => {
              const map = new google.maps.Map(document.getElementById('map'), this.mapOptions)
              const marker = new google.maps.Marker({
                position: {
                  lat: this.location.latitude,
                  lng: this.location.longitude
                },
                map: map,
                draggable: true
              })
              google.maps.event.addListener(marker, 'dragend', () => {
                if (this.newPosition) {
                  map.setZoom(8)
                  this.newPosition = false
                }
                var position = marker.getPosition()
                // console.log(position.lat(), position.lng())
                this.postData.location.latitude = position.lat()
                this.postData.location.longitude = position.lng()
                console.log(this.postData)
              })
            })
            .catch(e => {
              // do something
              console.log(e)
            })
          // console.log(location)
          // console.log(this.postData)
          // console.log(this.location)
        } catch (error) {
          this.$notify({
            type: 'error',
            text: 'Algo salió mal al consultar la ubicación del negocio, vuelva a la lista de negocios e intente nuevamente'
          })
          // console.info(error)
        }
      } else {
        // console.log('sin parametros')
        // this.loadingForm = false
        this.$router.push({ name: 'companies' })
      }
    },
    async updateMarkerMap () {
      const isValidLongitud = await this.$refs['longitude'].validate()
      const isValidLatitud = await this.$refs['latitude'].validate()
      if (isValidLongitud.valid && isValidLatitud.valid) {
        this.location.latitude = this.postData.location.latitude
        this.location.longitude = this.postData.location.longitude
        this.errorCoordinates = null
      } else {
        this.errorCoordinates = 'Ingrese coordenas validas para actualizar el mapa ó arrastre el marcador'
      }
      if (!this.$route.params.id) {
        this.zoom = 6
      }
    },
    dragEndEvent (e) {
      this.barData.bar.location.latitude = e.latitude
      this.barData.bar.location.longitude = e.longitude
      // console.log(e)
      // this.postData.location.latitude = e.latitude
      // this.postData.location.longitude = e.longitude
      this.zoom = 6
      // this.errorCoordinates = null
    },
    created () {
      // We need to set mapbox-gl library here in order to use it in template
      this.mapbox = Mapbox
    },
    async save () {
      // console.log('Guardar ubicacion nueva')
      // console.log(this.postData)
      this.loading = true
      try {
        await _barsService.edit(this.$route.params.id, this.postData)
        this.$notify({
          type: 'success',
          text: 'Datos modificados correctamente'
        })
        this.loading = false
        this.initialize()
        // this.$router.push({ name: 'companies' })
        // console.log(this.modules.modules[0])
      } catch (error) {
        this.$notify({
          type: 'error',
          text: error.msg
        })
        this.loading = false
        // this.disabled = false
        // console.info(error)
      }
    },
    close () {
      this.$router.push({ path: 'companies' }).catch(() => {})
    }
  }
}
